const hostname = process.env.NODE_ENV === 'development' ? 
    'https://localhost:7076/' :
    'https://api.taniastar.com/'
// const headers: HeadersInit = [
    //     ['Referer', 'taniastar.com'], 
    //     ['Content-Security-Policy', 'upgrade-insecure-requests'],
    //     ['Access-Control-Allow-Origin', 'https://taniastar.com/']
    // ]

export const get = async (url: string, init?: RequestInit) => {
    const response = await fetch(`${hostname}${url}`, { ...init })

    return response.json()
}

export const send = async (url: string, init?: RequestInit) => {
    await fetch(`${hostname}${url}`, { ...init })
}