import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import { App } from './App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

initializeIcons();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
