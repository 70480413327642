import { BookFragment } from 'utilities/useLibrarySearch'

import becomingGod1_fragment from './becoming_god_1_fragment.txt'
import becomingGod2_fragment from './becoming_god_2_fragment.txt'
import becomingGod3_fragment from './becoming_god_3_fragment.txt'

import blackBentleyOwner_fragment from './bentli_fragment.txt'
import coma1_fragment from './coma_1_fragment.txt'
import coma2_fragment from './coma_2_fragment.txt'
import fatum1_fragment from './fatum_1_fragment.txt'
import fatum2_fragment from './fatum_2_fragment.txt'
import letsRide_fragment from './lets_ride_fragment.txt'
import metroland_fragment from './metroland_fragment.txt'
import onlineLonelinessness_fragment from './online_lonelinessness_fragment.txt'

import kovrik1_fragment from './kovrik_1_fragment.txt'
import kovrik2_fragment from './kovrik_2_fragment.txt'
import kovrik3_fragment from './kovrik_3_fragment.txt'

import knowItAll1_fragment from './know_it_all_1_fragment.txt'
import knowItAll2_fragment from './know_it_all_2_fragment.txt'
import knowItAll3_fragment from './know_it_all_3_fragment.txt'
import knowItAll4_fragment from './know_it_all_4_fragment.txt'
import knowItAll5_fragment from './know_it_all_5_fragment.txt'

export const useBooksFragments = () => {
    const booksFragments: BookFragment[] = [
        { id: "1-za_jemchugom", fragment: knowItAll1_fragment },
        { id: "2-noch_vampirov", fragment: knowItAll2_fragment },
        { id: "3-peschera_drakona", fragment: knowItAll3_fragment },
        { id: "4-magiya_samosvetov", fragment: knowItAll4_fragment },
        { id: "5-carstvo_ledi", fragment: knowItAll5_fragment },
        { id: "bentli", fragment: blackBentleyOwner_fragment },
        { id: "bog-1", fragment: becomingGod1_fragment },
        { id: "bog-2", fragment: becomingGod2_fragment },
        { id: "bog-3", fragment: becomingGod3_fragment },
        { id: "detstvo", fragment: undefined },
        { id: "dushi", fragment: undefined },
        { id: "fatum-1", fragment: fatum1_fragment },
        { id: "fatum-2", fragment: fatum2_fragment },
        { id: "koma-1", fragment: coma1_fragment },
        { id: "koma-2", fragment: coma2_fragment },
        { id: "koma-3", fragment: undefined },
        { id: "kot-1", fragment: kovrik1_fragment },
        { id: "kot-2", fragment: kovrik2_fragment },
        { id: "kot-3", fragment: kovrik3_fragment },
        { id: "kot-4", fragment: undefined },
        { id: "metrolend", fragment: metroland_fragment },
        { id: "neodinochestvo", fragment: onlineLonelinessness_fragment },
        { id: "pesnya_zemli", fragment: undefined },
        { id: "prokachu", fragment: letsRide_fragment }
    ]

    return { booksFragments }
}