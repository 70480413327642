import { ImageFile } from 'utilities/useLibrarySearch'

import notFoundImage from './common/not_found_image.png'

import FacebookImage from './common/facebook.png'
import InstagramImage from './common/instagram.png'
import LinkedinImage from './common/linkedin.png'
import LitresImage from './common/litres.png'
import TwitterImage from './common/twitter.png'
import VkontakteImage from './common/vkontakte.png'

import taniaStarCollage_image from './author/tania_star_collage.png'

import zaJemchugom_bookCover from './bookCovers/1-za_jemchugom.png'
import nochVampirov_bookCover from './bookCovers/2-noch_vampirov.png'
import pescheraDrakona_bookCover from './bookCovers/3-peschera_drakona.png'
import magiyaSamosvetov_bookCover from './bookCovers/4-magiya_samosvetov.png'
import carstvoLedi_bookCover from './bookCovers/5-carstvo_ledi.png'
import bentli_bookCover from './bookCovers/bentli.png'
import bog1_bookCover from './bookCovers/bog-1.png'
import bog2_bookCover from './bookCovers/bog-2.png'
import bog3_bookCover from './bookCovers/bog-3.png'
import detstvo_bookCover from './bookCovers/detstvo.png'
import dushi_bookCover from './bookCovers/dushi.png'
import fatum1_bookCover from './bookCovers/fatum-1.png'
import fatum2_bookCover from './bookCovers/fatum-2.png'
import koma1_bookCover from './bookCovers/koma-1.png'
import koma2_bookCover from './bookCovers/koma-2.png'
import koma3_bookCover from './bookCovers/koma-3.png'
import kot1_bookCover from './bookCovers/kot-1.png'
import kot2_bookCover from './bookCovers/kot-2.png'
import kot3_bookCover from './bookCovers/kot-3.png'
import kot4_bookCover from './bookCovers/kot-4.png'
import metrolend_bookCover from './bookCovers/metrolend.png'
import neodinochestvo_bookCover from './bookCovers/neodinochestvo.png'
import pesnya_zemli_bookCover from './bookCovers/pesnya_zemli.png'
import prokachu_bookCover from './bookCovers/prokachu.png'

import zaJemchugom_bookCover_preview from './bookCovers/preview/1-za_jemchugom.png'
import nochVampirov_bookCover_preview from './bookCovers/preview/2-noch_vampirov.png'
import pescheraDrakona_bookCover_preview from './bookCovers/preview/3-peschera_drakona.png'
import magiyaSamosvetov_bookCover_preview from './bookCovers/preview/4-magiya_samosvetov.png'
import carstvoLedi_bookCover_preview from './bookCovers/preview/5-carstvo_ledi.png'
import bentli_bookCover_preview from './bookCovers/preview/bentli.png'
import bog1_bookCover_preview from './bookCovers/preview/bog-1.png'
import bog2_bookCover_preview from './bookCovers/preview/bog-2.png'
import bog3_bookCover_preview from './bookCovers/preview/bog-3.png'
import detstvo_bookCover_preview from './bookCovers/preview/detstvo.png'
import dushi_bookCover_preview from './bookCovers/preview/dushi.png'
import fatum1_bookCover_preview from './bookCovers/preview/fatum-1.png'
import fatum2_bookCover_preview from './bookCovers/preview/fatum-2.png'
import koma1_bookCover_preview from './bookCovers/preview/koma-1.png'
import koma2_bookCover_preview from './bookCovers/preview/koma-2.png'
import koma3_bookCover_preview from './bookCovers/preview/koma-3.png'
import kot1_bookCover_preview from './bookCovers/preview/kot-1.png'
import kot2_bookCover_preview from './bookCovers/preview/kot-2.png'
import kot3_bookCover_preview from './bookCovers/preview/kot-3.png'
import kot4_bookCover_preview from './bookCovers/preview/kot-4.png'
import metrolend_bookCover_preview from './bookCovers/preview/metrolend.png'
import neodinochestvo_bookCover_preview from './bookCovers/preview/neodinochestvo.png'
import pesnya_zemli_bookCover_preview from './bookCovers/preview/pesnya_zemli.png'
import prokachu_bookCover_preview from './bookCovers/preview/prokachu.png'

export const useResourceImages = () => {
    const miscImages: ImageFile[] = [
        { id: "not-found", fullRes: notFoundImage, preview: notFoundImage },
    ]

    const partnerWebsitesIcons: ImageFile[] = [
        { id: "facebook", fullRes: FacebookImage },
        { id: "instagram", fullRes: InstagramImage },
        { id: "linkedin", fullRes: LinkedinImage },
        { id: "litres", fullRes: LitresImage },
        { id: "twitter", fullRes: TwitterImage },
        { id: "vkontakte", fullRes: VkontakteImage }
    ]

    const authorImages: ImageFile[] = [
        { id: "tania-star-collage", fullRes: taniaStarCollage_image, preview: taniaStarCollage_image },
    ]
    
    const bookCovers: ImageFile[] = [
        { id: "1-za_jemchugom", fullRes: zaJemchugom_bookCover, preview: zaJemchugom_bookCover_preview },
        { id: "2-noch_vampirov", fullRes: nochVampirov_bookCover, preview: nochVampirov_bookCover_preview },
        { id: "3-peschera_drakona", fullRes: pescheraDrakona_bookCover, preview: pescheraDrakona_bookCover_preview },
        { id: "4-magiya_samosvetov", fullRes: magiyaSamosvetov_bookCover, preview: magiyaSamosvetov_bookCover_preview },
        { id: "5-carstvo_ledi", fullRes: carstvoLedi_bookCover, preview: carstvoLedi_bookCover_preview },
        { id: "bentli", fullRes: bentli_bookCover, preview: bentli_bookCover_preview },
        { id: "bog-1", fullRes: bog1_bookCover, preview: bog1_bookCover_preview },
        { id: "bog-2", fullRes: bog2_bookCover, preview: bog2_bookCover_preview },
        { id: "bog-3", fullRes: bog3_bookCover, preview: bog3_bookCover_preview },
        { id: "detstvo", fullRes: detstvo_bookCover, preview: detstvo_bookCover_preview },
        { id: "dushi", fullRes: dushi_bookCover, preview: dushi_bookCover_preview },
        { id: "fatum-1", fullRes: fatum1_bookCover, preview: fatum1_bookCover_preview },
        { id: "fatum-2", fullRes: fatum2_bookCover, preview: fatum2_bookCover_preview },
        { id: "koma-1", fullRes: koma1_bookCover, preview: koma1_bookCover_preview },
        { id: "koma-2", fullRes: koma2_bookCover, preview: koma2_bookCover_preview },
        { id: "koma-3", fullRes: koma3_bookCover, preview: koma3_bookCover_preview },
        { id: "kot-1", fullRes: kot1_bookCover, preview: kot1_bookCover_preview },
        { id: "kot-2", fullRes: kot2_bookCover, preview: kot2_bookCover_preview },
        { id: "kot-3", fullRes: kot3_bookCover, preview: kot3_bookCover_preview },
        { id: "kot-4", fullRes: kot4_bookCover, preview: kot4_bookCover_preview },
        { id: "metrolend", fullRes: metrolend_bookCover, preview: metrolend_bookCover_preview },
        { id: "neodinochestvo", fullRes: neodinochestvo_bookCover, preview: neodinochestvo_bookCover_preview },
        { id: "pesnya_zemli", fullRes: pesnya_zemli_bookCover, preview: pesnya_zemli_bookCover_preview },
        { id: "prokachu", fullRes: prokachu_bookCover, preview: prokachu_bookCover_preview }
    ]

    return { miscImages, authorImages, partnerWebsitesIcons, bookCovers }
}