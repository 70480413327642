import React from 'react'
import './IconButton.css';

export function IconButton({ imgPath, prefix, redirectUrl, tooltip, width } : { imgPath: string, prefix: string, redirectUrl: string | undefined, tooltip: string, width: number }) {
    return redirectUrl ? <a
        className='litres-download-button' 
        href={redirectUrl}
        title={`Посмотреть на ${tooltip}`}
        target='_blank' 
        rel="noreferrer"
    >
        {prefix} <img src={imgPath} width={width} alt='litres-link'/>
    </a> :
    <p>
        <img src={imgPath} width={width} alt='litres-link'/>
    </p>
}