import React, { useState } from 'react'
import { DetailsButton } from 'components/Buttons/DetailsButton'
import { useDocuments } from '../../resources/documents'
import { useNavigate } from 'react-router-dom'
import { GalleryItem } from 'components/Gallery/gallery'
import { useBooksFragments } from 'resources/bookFragments'
import { IconButton } from 'components/Buttons/IconButton'
import { useResourceImages } from 'resources'
import { get, send } from 'utilities/http'
import './InfoBlock.css'

type ArticleType = 'image-none' | 'image-right' | 'image-left'

type Page = {
    number: number
    content: string[]
}

export type Chapter = {
    chaptersCount: number
    chapterName: string
    pages: Page[]
}

export type InfoBlockProps = {
    articleType: ArticleType
    galleryItem: GalleryItem | undefined
    article?: string[]
    fontSize?: number
    imageWidth?: number
    renderDetailsButton?: boolean
    renderReadOnlineButton?: boolean
    renderBackButton?: boolean
    renderDescription?: boolean
    renderDownloadButton?: boolean
    renderFragment?: boolean
    litresLink?: string
    renderLitresLink?: boolean
    renderLitresAudioLink?: boolean
    litresAudioLink?: string
}

export function InfoBlock({
    articleType,
    galleryItem,
    article,
    fontSize,
    imageWidth,
    renderDetailsButton = false,
    renderReadOnlineButton = false,
    renderBackButton = false,
    renderDescription = true,
    renderDownloadButton = false,
    renderFragment = false,
    renderLitresAudioLink = false,
    litresLink,
    renderLitresLink,
    litresAudioLink
}: InfoBlockProps) {
    const { commonDocument } = useDocuments()
    const { booksFragments } = useBooksFragments()
    const navigate = useNavigate()
    const offsets = document.getElementById('chapter-title')?.getBoundingClientRect()
    var top = offsets && offsets.top

    const { partnerWebsitesIcons } = useResourceImages()
    const targetImageResource = partnerWebsitesIcons.find(x => x.id === commonDocument.info.contacts.internetResources.partnerWebsites[0].id)
    const litresItem = {
        ...commonDocument.info.contacts.internetResources.partnerWebsites[0],
        description: [],
        fullRes: { imagePath: targetImageResource?.fullRes, width: 80 },
        preview: { imagePath: targetImageResource?.fullRes, width: 80 }
    }
    const [chapter, setChapter] = useState<Chapter>({ chaptersCount: 0, chapterName: '', pages: [] })
    const [loadedChapter, setLoadedChapter] = useState<number>(1)
    const [pageIndex, setPageIndex] = useState<number>(0)

    const onDetailsClick = async () => {
        if (galleryItem && galleryItem.detailsLink) {
            const readBookFragment = async () => {
                const textFile = booksFragments.find(x => x.id === galleryItem.id)
                if (textFile?.fragment) {
                    return await fetch(textFile.fragment)
                        .then(r => r.text())
                        .then(text => text)
                }

                return ''
            }

            galleryItem.bookFragment = await readBookFragment()
            const linkedPageState = {
                state: {
                    book: galleryItem
                }
            }
            navigate(galleryItem.detailsLink, linkedPageState)
        }
    }

    const onDownloadClick = async () => {
        alert("Где-то тут можно будет скачать файл")
    }

    const onReadOnlineClick = () => {
        window.scrollTo(0, top ?? window.innerHeight * 0.5)
        console.log(galleryItem && galleryItem.analytics)
        const sendAnalytics = async () => {
            galleryItem && galleryItem.analytics && await send(`analytics/read/${galleryItem.analytics}`)
        }

        sendAnalytics()
        onChapterLoad(1)
    }

    const onChapterLoad = async (chapter: number) => {
        window.scrollTo(0, top ?? window.innerHeight * 0.5)
        const text = galleryItem && galleryItem.apiLink && await get(`books/${galleryItem.apiLink}/${chapter}`)
        setChapter({
            chaptersCount: text[0].chaptersCount,
            chapterName: text[0].chapterName,
            pages: text[0].pages
        })
        setPageIndex(0)
    }

    const onBackClick = () => {
        navigate(-1)
    }

    return <>
        <div className={articleType !== 'image-none' ? `info-block-${articleType}` : 'info-block'}>
            <div className='article'>
                {article ? article.map((x, key) => {
                    return <p key={key}>{x}</p>
                }) : null
                }
                {renderDescription && galleryItem ?
                    galleryItem.description.map((x, key) => {
                        return <p key={key} style={fontSize ? { fontSize: fontSize } : undefined}>{x}</p>
                    }) :
                    null
                }
                {renderFragment && galleryItem && galleryItem.bookFragment &&
                    <>
                        {loadedChapter === 1 && chapter.pages.length <= 0 && galleryItem && galleryItem.title &&
                            <div>
                                <div className='header'>{galleryItem.title}</div>
                                <div className='book-fragment'>
                                    <p>. . . . .</p>
                                    <pre>{galleryItem.bookFragment}</pre>
                                    <p>. . . . .</p>
                                </div>
                            </div>
                        }
                        {renderReadOnlineButton ? (galleryItem?.apiLink && chapter.pages.length <= 0 ?
                            <DetailsButton
                                text={commonDocument.buttons.readOnline.title}
                                tooltip={commonDocument.buttons.readOnline.tooltip}
                                width={commonDocument.buttons.readOnline.title.length * 22}
                                iconName='Read'
                                onClick={onReadOnlineClick}
                            /> :
                            <div>
                                <div id='chapter-title' className='header'>{chapter.chapterName}</div>
                                {renderPageNumbers(pageIndex, commonDocument, setPageIndex, chapter, top)}
                                <div>
                                    {chapter.pages[pageIndex].content.map((x, key) => {
                                        return x.includes('[иллюстрация_') ? 
                                        <div
                                            key={key}
                                            style={{ textAlign: 'left', textIndent: '1rem', fontStyle: 'italic', fontSize: '1rem' }}
                                        >
                                            {x}
                                        </div> :
                                        <div
                                            key={key}
                                            style={{ textAlign: 'left', textIndent: '3rem' }}
                                        >
                                            {x}
                                        </div>
                                    })}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div className='splitter' />
                                </div>
                                {renderPageNumbers(pageIndex, commonDocument, setPageIndex, chapter, top)}
                                {renderChapterNumbers(loadedChapter, commonDocument, chapter, setLoadedChapter, onChapterLoad, top)}
                            </div>) : null
                        }
                    </>
                }
                <div className='download-section'>
                    {renderLitresAudioLink && litresAudioLink !== undefined ?
                        <IconButton
                            imgPath={litresItem.fullRes.imagePath ?? ''}
                            prefix={commonDocument.buttons.downloadAudio.title}
                            redirectUrl={litresAudioLink}
                            tooltip={litresItem.title}
                            width={60}
                        /> :
                        null
                    }
                    {renderLitresLink &&
                        <IconButton
                            imgPath={litresItem.fullRes.imagePath ?? ''}
                            prefix={commonDocument.buttons.download.title}
                            redirectUrl={litresLink}
                            tooltip={litresItem.title}
                            width={60}
                        />
                    }
                    {renderDownloadButton &&
                        <DetailsButton
                            text={commonDocument.buttons.download.title}
                            tooltip={commonDocument.buttons.download.tooltip}
                            width={commonDocument.buttons.download.title.length * 22}
                            iconName='Download'
                            onClick={onDownloadClick}
                        />
                    }
                </div>
                {renderDetailsButton && galleryItem && galleryItem.detailsLink ?
                    <DetailsButton
                        text={commonDocument.buttons.details.title}
                        tooltip={commonDocument.buttons.details.tooltip}
                        width={commonDocument.buttons.details.title.length * 22}
                        iconName='Info'
                        onClick={onDetailsClick}
                    /> :
                    null
                }
            </div>
            {galleryItem && articleType !== 'image-none' ?
                <div className={articleType}>
                    <img
                        src={galleryItem && galleryItem.fullRes.imagePath}
                        width={imageWidth ?? galleryItem.fullRes.width}
                        alt={galleryItem.title}
                    />
                </div> :
                null
            }
        </div>
        {renderBackButton ?
            <>
                <div className='splitter' />
                <div className='page-footer'>
                    <DetailsButton
                        text={commonDocument.buttons.back.title}
                        tooltip={commonDocument.buttons.back.tooltip}
                        width={commonDocument.buttons.back.title.length * 22}
                        iconName='Back' onClick={onBackClick}
                    />
                </div>
            </> :
            null
        }
    </>
}

const renderPageNumbers = (pageIndex: number, commonDocument: any, setPageIndex: any, chapter: any, top: any) => {
    const onNextPageClick = () => {
        window.scrollTo(0, top ?? window.innerHeight * 0.5)
        setPageIndex(pageIndex + 1)
    }

    const onPreviousPageClick = () => {
        window.scrollTo(0, top ?? window.innerHeight * 0.5)
        setPageIndex(pageIndex - 1)
    }

    return <>
        <div className='page-number-grid'>
            <div>
                {pageIndex > 0 && <DetailsButton
                    text={commonDocument.buttons.previousPage.title}
                    tooltip={commonDocument.buttons.previousPage.tooltip}
                    width={200}
                    onClick={onPreviousPageClick}
                />}
            </div>
            <div style={{ fontStyle: 'italic', fontSize: '1rem' }}>Страница {pageIndex + 1}/{chapter.pages.length}</div>
            <div>
                {pageIndex < chapter.pages.length - 1 && <DetailsButton
                    text={commonDocument.buttons.nextPage.title}
                    tooltip={commonDocument.buttons.nextPage.tooltip}
                    width={200}
                    onClick={onNextPageClick}
                />
                }
            </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div className='splitter' />
        </div>
    </>
}

const renderChapterNumbers = (loadedChapter: any, commonDocument: any, chapter: any, setLoadedChapter: any, onChapterLoad: any, top: any) => {
    const onNextChapterClick = () => {
        window.scrollTo(0, top ?? window.innerHeight * 0.5)
        setLoadedChapter(loadedChapter + 1)
        onChapterLoad(loadedChapter + 1)
    }

    const onPreviousChapterClick = () => {
        setLoadedChapter(loadedChapter - 1)
        onChapterLoad(loadedChapter - 1)
    }

    return <>
        <div className='page-number-grid'>
            <div>
                {loadedChapter > 1 && <DetailsButton
                    text={commonDocument.buttons.previousChapter.title}
                    tooltip={commonDocument.buttons.previousChapter.tooltip}
                    width={480}
                    onClick={onPreviousChapterClick}
                />}
            </div>
            <div style={{ fontStyle: 'italic', fontSize: '1rem' }}>Глава {loadedChapter}/{chapter.chaptersCount}</div>
            <div>
                {loadedChapter < chapter.chaptersCount && <DetailsButton
                    text={commonDocument.buttons.nextChapter.title}
                    tooltip={commonDocument.buttons.nextChapter.tooltip}
                    width={480}
                    onClick={onNextChapterClick}
                />}
            </div>
        </div>
    </>
}