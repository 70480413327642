import React from 'react'
import { Icon } from '@fluentui/react/lib/Icon';

import './DetailsButton.css';

export function DetailsButton({ text, tooltip, width, iconName, onClick } : { text: string, tooltip: string, width: number, onClick: () => void, iconName?: string, }) {
    const ButtonIcon = () => <Icon iconName={iconName} />;

    return <button 
        className='details-button' 
        style={{ width: width }} 
        title={tooltip} 
        onClick={onClick}
    >
        {text}
        {iconName && <ButtonIcon />}
    </button>
}