import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { InfoBlock } from 'components/InfoBlock/InfoBlock'
import { GalleryItem } from 'components/Gallery/gallery';
import { DetailsButton } from 'components';
import { useDocuments } from 'resources/documents';
import { useBrowserWindow } from 'utilities';
import './pages.css'
import { send } from 'utilities/http';

export type NavLocationState = {
    book: GalleryItem
}

export function BookPage() {
    window.scrollTo(0, 0)
    const screenWidth = useBrowserWindow().screenSize.width
    const { commonDocument } = useDocuments()
    const navigate = useNavigate()
    
    const location = useLocation()
    const state: NavLocationState = location.state
    const book = state?.book

    const bookWithFragment = {
        ...book,
        title: 'Выдержка из книги'
    }

    const onBackClick = () => {
        navigate(-1)
    }

    const pageLoad = async () => {
        await send(`analytics/${book.analytics}`)
    }

    useEffect(() => {
        if(book.analytics && book.analytics !== ''){
            pageLoad()
        }
    // eslint-disable-next-line
    }, [])

    return book ? <div className='page'>
        <div className='page-header'>
            <DetailsButton 
                text={commonDocument.buttons.back.title} 
                tooltip={commonDocument.buttons.back.tooltip} 
                width={commonDocument.buttons.back.title.length * 22} 
                iconName='Back' 
                onClick={onBackClick} 
            />
        </div>
        <InfoBlock 
            articleType='image-left'
            galleryItem={book}
            imageWidth={screenWidth > 450 ? 400 : screenWidth * 0.8}
        />
        <InfoBlock 
            articleType='image-none' 
            galleryItem={bookWithFragment.bookFragment !== undefined ? bookWithFragment : book}
            renderDescription={false}
            renderReadOnlineButton
            renderBackButton
            renderFragment={bookWithFragment.bookFragment !== undefined}
            renderLitresAudioLink
            litresLink={book.litresLink}
            renderLitresLink
            litresAudioLink={book.litresAudioLink}
        />
    </div> :
    <></>
}
