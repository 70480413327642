import React, { useEffect } from 'react'
import { InfoBlock } from 'components/InfoBlock/InfoBlock'
import { useDocuments } from '../resources/documents'
import { useResourceImages } from '../resources/images'
import { Gallery, GalleryItem } from 'components/Gallery/gallery'
import { LibraryBook } from 'utilities/useLibrarySearch'
import './pages.css'
import { send } from 'utilities/http'

export function HomePage() {
    const { commonDocument, libraryDocument } = useDocuments()
    const { bookCovers } = useResourceImages()

    const buildBooks = (bookSet: LibraryBook[]): GalleryItem[] => {
        return bookSet.map(book => {
            return {
                ...book,
                fullRes: { imagePath: bookCovers.find(bookCover => bookCover.id === book.id)?.fullRes, width: 300 },
                preview: { imagePath: bookCovers.find(bookCover => bookCover.id === book.id)?.preview, width: 200 },
                detailsLink: `/library/${book.id}`,
                litresLink: book.litresLink
            }
        })
    }

    const allBooks = buildBooks(libraryDocument.books)

    const allAudioBooks = allBooks.filter(x => x.litresAudioLink !== '').map(book => {
        return {
            ...book, 
            preview: {
                imagePath: book.preview.imagePath,
                width: 100
            }
        }})

    const pageLoad = async () => {
        await send(`analytics/home`)
    }
    
    useEffect(() => {
        pageLoad()
    // eslint-disable-next-line
    }, [])

    return <div className='page'>
        <Gallery header='Аудиокниги' items={allAudioBooks} />
        <div className='splitter'/>
        <InfoBlock articleType='image-none' galleryItem={undefined} article={commonDocument.info.homePageWelcomeNote}/>
        <div className='splitter'/>
        <Gallery header="Библиотека" items={allBooks} />
    </div>
}
