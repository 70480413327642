import React from 'react'
import { InfoBlock } from 'components/InfoBlock/InfoBlock'
import { useDocuments } from '../resources/documents'
import { useResourceImages } from '../resources/images'

import { LibraryBook } from 'utilities/useLibrarySearch'
import { GalleryItem } from 'components/Gallery/gallery'
// import { useBrowserWindow } from 'utilities'
import './pages.css'

type GeanreGallery = {
    books: GalleryItem[]
}

export function LibraryPage() {
    const { libraryDocument } = useDocuments()
    const { bookCovers } = useResourceImages()
    // const screenWidth = useBrowserWindow().screenSize.width

    const buildBooks = (bookSet: LibraryBook[]): GalleryItem[] => {
        return bookSet.map(book => {
            return {
                ...book,
                fullRes: { imagePath: bookCovers.find(bookCover => bookCover.id === book.id)?.fullRes, width: 300 },
                preview: { imagePath: bookCovers.find(bookCover => bookCover.id === book.id)?.preview, width: 200 },
                detailsLink: `/library/${book.id}`,
                bookFragment: ''
            }
        })
    }

    // const [geanreOpen, setGeanreOpen] = useState([false, false, false, false])

    const libraryGallery: GeanreGallery[] = [
        { ...libraryDocument.books, books: buildBooks(libraryDocument.books) }
    ]

    // const handleAccordion = (geanre: number) => {
    //     let accordionArray = [...geanreOpen]
    //     accordionArray[geanre] = !accordionArray[geanre]
    //     setGeanreOpen(accordionArray)
    // }

    return <div className='page'>
        {libraryGallery.map((geanre, geanreKey) => {
            return <div key={geanreKey}>
                {/* <div className='geanre-header' style={{ minWidth: screenWidth > 1024 ? '1024px' : screenWidth, maxWidth: screenWidth < 480 ? screenWidth : undefined }} onClick={() => handleAccordion(geanreKey)}>
                    <div className='geanre-underline'/>
                    <div className='geanre-title'>
                        {geanre.title}
                    </div>
                    <div className='geanre-underline'/>
                </div> */}
                <div className='article'>
                    {/* {geanre.description?.map((line, key) => {
                        return <div key={key}>{line}</div>
                    })} */}
                </div>
                {geanre.books.map((book, key) => {
                        return <InfoBlock 
                            key={key}
                            articleType='image-left'
                            galleryItem={book}
                            renderDetailsButton
                        />
                    })
                }
            </div>
        })}
    </div>
}
