import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useDocuments } from '../../resources/documents';
import './Navbar.css';

export function Navbar() {
    const { commonDocument } = useDocuments()
    const navigate = useNavigate()

    return <nav>
        <h3 onClick={() => navigate('/')}>{commonDocument.headers.website.title}</h3>
        <ul className='nav-links'>
            <NavLink to='/' className={({ isActive }) => isActive ? 'nav-active' : undefined}>
                {commonDocument.headers.homePage.title}
            </NavLink>
            <NavLink to='/contacts' className={({ isActive }) => isActive ? 'nav-active' : undefined}>
                {commonDocument.headers.contacts.title}
            </NavLink>
        </ul>
    </nav>
}