import { Library, LibraryBook } from 'utilities/useLibrarySearch'
import commonDocument from './common.json'
import libraryJson from './library.json'

export const useDocuments = () => {
    const libraryDocument: Library = {
        books: libraryJson.books as LibraryBook[]
    }
    
    return { commonDocument, libraryDocument }
}