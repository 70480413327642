import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar } from './components';
import { HomePage, LibraryPage, BookPage, ContactsPage } from './pages';
import { Footer } from 'Footer';
import './App.css';
import { NotFoundPage } from 'pages/NotFoundPage';
// import { generateSiteMap } from 'pages/Sitemap.xml';
// import { useDocuments } from 'resources/documents';

export const App = () => {
    // const { libraryDocument } = useDocuments()
    // const allBooks: LibraryBook[] = libraryDocument.books

    // const siteMap = generateSiteMap(allBooks)
    // console.log(siteMap)
    return <div className='app'>
        <BrowserRouter>
            <div style={{ display: 'grid', overflow: 'auto' }} >
                <Navbar />
                <Routes>
                    <Route path='/' element={ <HomePage /> }/>
                    <Route path='/library' element={ <LibraryPage /> }/>
                    <Route path='/library/:bookId' element={ <BookPage /> }/>
                    <Route path='/contacts' element={ <ContactsPage /> }/>
                    <Route path='*' element={ <NotFoundPage /> }/>
                </Routes>
            </div>
        </BrowserRouter>
        <Footer />
    </div>
}