import React from 'react'
import { useNavigate } from "react-router-dom"

import './gallery.css'
import { useBooksFragments } from 'resources/bookFragments'
import { useResourceImages } from 'resources'

export type GalleryItem = {
    id: string
    title: string
    category: string
    description: string[]
    fullRes: ImageProps
    preview: ImageProps
    detailsLink?: string
    externalLink?: string
    bookFragment?: string
    apiLink?: string
    analytics?: string
    litresLink?: string
    litresAudioLink?: string
}

type GalleryProps = {
    header: string
    items: GalleryItem[]
}

type ImageProps = {
    imagePath?: string,
    width?: number,
    height?: number
}

export function Gallery({ header, items } : GalleryProps) {
    const navigate = useNavigate()
    const { booksFragments } = useBooksFragments()
    const { bookCovers } = useResourceImages()

    const handleGalleryItemClick = async (item: GalleryItem) => {
        const readBookFragment = async () => {
            const textFile = booksFragments.find(x => x.id === item.id)
            if(textFile?.fragment) {
                return await fetch(textFile.fragment)
                    .then(r => r.text())
                    .then(text => text)
            }

            return undefined
        }

        const bookImage = bookCovers.find(x => x.id === item.id)
        if(item.detailsLink) {
            item.bookFragment = await readBookFragment()
            if(bookImage){
                item.fullRes = { imagePath: bookImage.fullRes }
            }
            const linkedPageState = { 
                state: { 
                    book: item,
                } 
            }
            navigate(item.detailsLink, linkedPageState)
        }
    }

    return <>
        {header ? 
            <div className='header'>{header}</div> : 
            null
        } 
        <div className='info-block'>
            <div className='block-gallery'>
                {items.map((x, key) => {
                    return x.externalLink ? 
                    <a key={key} href={x.externalLink} target='_blank' rel="noreferrer">
                        <img src={x.preview.imagePath} width={x.preview.width} alt={x.title} />
                    </a> :
                    <p key={key} onClick={() => handleGalleryItemClick(x)} title={x.title}>
                        <img src={x.preview.imagePath} width={x.preview.width} alt={x.title} />
                    </p>
                })}
            </div>
        </div>
    </>
}