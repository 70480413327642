import React from 'react'

import './Footer.css';

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return <div className='footer'>
        <ul className='footer-lines'>
            <li className='footer-line'>Tania Star © {currentYear}</li>
            <li className='footer-line'>Location: Mogilev, Belarus</li>
        </ul>
    </div>
}