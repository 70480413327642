import React from 'react'
import { InfoBlock } from 'components/InfoBlock/InfoBlock'
import { useDocuments } from '../resources/documents'
import { useResourceImages } from 'resources'
import { useBrowserWindow } from 'utilities'
import './pages.css'

export function NotFoundPage() {
    const { commonDocument } = useDocuments()
    const { miscImages } = useResourceImages()
    const screenWidth = useBrowserWindow().screenSize.width
    
    return <div className='page'>
        <img 
            src={miscImages.find(x => x.id === 'not-found')?.fullRes} 
            width={screenWidth > 480 ? 480 : screenWidth * 0.8}
            style={{ padding: '5rem 0' }}
            alt='not-found'
        />
        <InfoBlock 
            articleType='image-none' 
            galleryItem={undefined}
            article={commonDocument.info.pageNotFoundStory}
        />
    </div>
}
