import React, { useState } from 'react'
import { InfoBlock } from 'components/InfoBlock/InfoBlock'
import { useDocuments } from '../resources/documents'
import { Gallery, GalleryItem } from 'components/Gallery/gallery'
import { useResourceImages } from '../resources/images'
import { DetailsButton } from 'components'
import emailjs from '@emailjs/browser'

import './pages.css'
import { useBrowserWindow } from 'utilities'

export function ContactsPage() {
    const { commonDocument } = useDocuments()
    const internetResources: GalleryItem[] = []
    const { partnerWebsitesIcons, authorImages } = useResourceImages()
    const screenWidth = useBrowserWindow().screenSize.width

    for (let i = 0; i < commonDocument.info.contacts.internetResources.partnerWebsites.length; i++) {
        const targetImageResource = partnerWebsitesIcons.find(x => x.id === commonDocument.info.contacts.internetResources.partnerWebsites[i].id)

        if(targetImageResource) {
            const newItem = {
                ...commonDocument.info.contacts.internetResources.partnerWebsites[i],
                category: 'social',
                description: [],
                fullRes: { imagePath: targetImageResource.fullRes, width: 80 },
                preview: { imagePath: targetImageResource.fullRes, width: 80 }
            }

            internetResources.push(newItem)
        }
    }

    const authorItem: GalleryItem = {
        id: 'tania-star',
        title: commonDocument.info.aboutAuthor.title,
        category: 'author',
        description: commonDocument.info.aboutAuthor.description,
        fullRes: { imagePath: authorImages.find(image => image.id === 'tania-star-collage')?.fullRes, width: screenWidth > 400 ? 360 : screenWidth * 0.8 },
        preview: { imagePath: authorImages.find(image => image.id === 'tania-star-collage')?.preview, width: screenWidth > 400 ? 360 : screenWidth * 0.8 }
    }

    const [nameInput, setNameInput] = useState('')
    const [emailInput, setEmailInput] = useState('')
    const [mailInput, setMailInput] = useState('')
    const [sendingMail, setSendingMail] = useState(false)
    const [mailSent, setMailSent] = useState(false)

    const handleSubmitMail = async () => {
        if(nameInput !== '' && mailInput !== '') {
            setSendingMail(true)
            const mailBody = {
                senderName: nameInput,
                senderEmail: emailInput ?? commonDocument.buttons.sendMail.emailEmpty,
                mailBody: mailInput
            }

            await emailjs.send('service_a9sgjdm', 'template_t7ejs34', mailBody, '8o5m3RzMboDeWa-Na')
                .then((_) => {
                    setMailSent(true)
                    setMailInput('')
                    setSendingMail(false)
                }, (_) => {
                    setSendingMail(false)
                    alert('Что-то поломалось. Попробуйте снова')
                })
        } else {
            alert(`${nameInput === '' ? '\nВы забыли заполнить поле с именем' : ''}${mailInput === '' ? '\nВы забыли заполнить текст письма' : ''}`)
        }
    }

    const inputsWidth = screenWidth * 0.8 > 800 ? 800 : screenWidth * 0.8

    return <div className='page'>
        <InfoBlock 
            articleType='image-left'
            galleryItem={authorItem}
            renderDetailsButton
            fontSize={13}
        />
        <Gallery 
            header={commonDocument.info.contacts.internetResources.title}
            items={internetResources}
        />
        <div className='splitter'/>
        <div className='feedback-form'>
            <div className='labeled-input'>
                <label className='input-label'>{commonDocument.buttons.sendMail.nameFieldTitle}</label>
                <input 
                    className='name-input' 
                    style={{ minWidth: inputsWidth, maxWidth: inputsWidth }}
                    maxLength={65} 
                    value={nameInput} 
                    onChange={e => setNameInput(e.target.value)}
                />
            </div>
            <div className='labeled-input'>
                <label className='input-label'>{commonDocument.buttons.sendMail.emailFieldTitle}</label>
                <input 
                    className='name-input' 
                    style={{ minWidth: inputsWidth, maxWidth: inputsWidth }}
                    maxLength={65} 
                    value={emailInput} 
                    onChange={e => setEmailInput(e.target.value)}
                />
            </div>
            { mailSent ? 
                <div className='mail-sent'>
                    <label className='input-label'>{commonDocument.buttons.sendMail.thankYou}</label> :
                    <DetailsButton 
                        text={commonDocument.buttons.sendMail.titleAgain} 
                        tooltip={commonDocument.buttons.sendMail.tooltipAgain}
                        width={commonDocument.buttons.sendMail.titleAgain.length * 18} 
                        iconName='Refresh' 
                        onClick={() => setMailSent(false)} 
                    />
                </div> :
                <>
                    <div className='labeled-input'>
                        <label className='input-label'>{commonDocument.buttons.sendMail.mailFieldTitle}</label>
                        <textarea 
                            style={{ minWidth: inputsWidth, maxWidth: inputsWidth }}
                            className='mail-to-form'
                            rows={10}
                            cols={100}
                            maxLength={5000}
                            value={mailInput}
                            onChange={e => setMailInput(e.target.value)}
                        />
                    </div>
                    {sendingMail ? 
                        <div className='input-label'>Отправляем письмо...</div> :
                        <DetailsButton 
                            text={commonDocument.buttons.sendMail.title} 
                            tooltip={commonDocument.buttons.sendMail.tooltip}
                            width={commonDocument.buttons.sendMail.title.length * 18} 
                            iconName='Mail' 
                            onClick={handleSubmitMail} 
                        />
                    }
                </>
            }
        </div>
    </div>
}
